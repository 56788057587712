import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/navbar/navbar";
import {
  useCreateCustomQRMutation,
  useGetCustomQRQuery,
  useDeleteCustomQRMutation,
} from "../../Redux/services/UserApi";
import { useSelector, useDispatch } from "react-redux";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";

export default function CustomQRs() {
  const [qrValue, setQrValue] = useState({
    link: "",
    name: "",
  });
  const { data: qrs } = useGetCustomQRQuery();
  const [createCustomQR] = useCreateCustomQRMutation();
  const [deleteCustomQR] = useDeleteCustomQRMutation();

  const { t } = useTranslation();

  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const handleQRValues = e => {
    setQrValue(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCreateQR = async () => {
    const response = await createCustomQR({ url: qrValue.link, name: qrValue.name });

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("QRCodeCreate"), type: "success" })
        );
      }
    } catch (err) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }

    setQrValue({
      link: "",
      name: "",
    });
  };
  const handleDeleteQR = async id => {
    const response = await deleteCustomQR(id);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("QRCodeDelete"), type: "success" })
        );
      }
    } catch (err) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }
  };

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="container-xxl mt-5 pt-5">
        <h1 className="fs-3">{t("customQRs")}</h1>
        <div className="mt-3 d-flex flex-column gap-2">
          <input
            type="text"
            name="name"
            className="form-control mt-3"
            placeholder={t("name")}
            value={qrValue.name}
            onChange={e => handleQRValues(e)}
          />
          <input
            type="text"
            name="link"
            className="form-control"
            placeholder={t("url")}
            value={qrValue.link}
            onChange={e => handleQRValues(e)}
          />
          <button
            className="btn btn-primary-gradient mt-3"
            disabled={user?.pricingPlan?.qrLimit === qrs?.data?.length}
            onClick={handleCreateQR}
          >
            {t("generateQR")}
          </button>
        </div>
        <table className="table mt-3">
          <thead>
            <tr>
              <th>{t("name")}</th>

              <th>{t("qrCode")}</th>
              {user?.pricingPlan?.canAnalytics && <th>{t("analytics")}</th>}
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {qrs?.data?.map(qr => (
              <tr key={qr.id}>
                <td>{qr.name}</td>
                <td>
                  <img
                    src={qr.qr}
                    alt={qr.name}
                    style={{ width: "150px", height: "150px" }}
                  />
                </td>
                {user?.pricingPlan?.canAnalytics && (
                  <td>
                    <Link
                      to={`/qranalytics/${qr.guid}`}
                      className="btn btn-primary-gradient"
                    >
                      {t("analytics")}
                    </Link>
                  </td>
                )}

                <td>
                  <button
                    className="btn btn-danger ms-2"
                    onClick={() => handleDeleteQR(qr.id)}
                  >
                    {t("delete")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mb-3">
          <Link
            className="btn btn-primary-gradient mt-3"
            to="/auth/profile"
          >
            {t("backToProfile")}
          </Link>
        </div>
      </div>
    </>
  );
}
