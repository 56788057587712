import React from "react";

const Button = ({ func, text, isLoading }) => {
  return (
    <button
      onClick={func}
      className={`btn btn-primary btn-block ${isLoading ? "disabled" : ""}`}
    >
      {isLoading ? (
        <>
          {" "}
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
