import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import TradersHub from "../components/premium/TradersHub";
import HandleUser from "../pages/handleUser/HandleUser";
import Layout from "../pages/layout/layout";
import Landing from "../pages/landing/landing";
import Terms from "../pages/terms/terms";
import LayoutProfile from "../pages/layout/layoutProfile";
import Admin from "../pages/admin/Admin";
import Users from "../pages/users/users";
import UserProfile from "../pages/userprofile/UserProfile";
import PricingTier from "../pages/pricingTier/PricingTier";
import Subscription from "../pages/subscription/Subscription";
import Profile from "../pages/profile/profile";
import CustomQRs from "../pages/customQRs/CustomQRs";
import ShortURLs from "../pages/shortURLs/ShortURLs";
import Signup from "../pages/signup/signup";
import Signin from "../pages/signin/signin";
import ForgotPassword from "../pages/forgotpassword/forgotpassword";
import ResetPassword from "../pages/resetpassword/resetpassword";
import User from "../pages/user/user";
import QR from "../pages/qr/qr";
import CardQRViewAnalytics from "../pages/cardqranalytics/cardqranalytics";
import QRAnalytics from "../pages/qranalytics/qranalytics";
import ShortUrlAnalytics from "../pages/shorturlanalytics/shorturlanalytics";
import Analytics from "../pages/analytics/analytics";
import Logins from "../pages/logins/logins";
import TemplateOne from "../components/templates/templateOne";
import TemplateTwo from "../components/templates/templateTwo";
import NavigateQR from "../pages/q/NavigateQR";
import NavigateShortURL from "../pages/u/NavigateShortURL";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import AdminRoute from "./AdminRoute";
import SuperAdminRoute from "./SuperAdminRoute";
import Builder from "../pages/builder/Builder";
import Bc463 from "../components/premium/Bc463";
import TemplateThree from "../components/templates/templateThree";

export const router = createBrowserRouter([
  {
    path: "/tradershub",
    element: <TradersHub isUserPage={true} />,
  },
  {
    path: "/bc463",
    element: <Bc463 isUserPage={true} />,
  },
  {
    path: "/del",
    element: (
      <SuperAdminRoute>
        <HandleUser />
      </SuperAdminRoute>
    ),
    index: true,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutProfile />,
    children: [
      {
        path: "terms",
        element: <Terms />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <AdminRoute>
        <Admin />
      </AdminRoute>
    ),
    index: true,
  },
  {
    path: "admin/users",
    element: (
      <AdminRoute>
        <Users />
      </AdminRoute>
    ),
  },
  {
    path: "admin/users/:userId",
    element: (
      <AdminRoute>
        <UserProfile />
      </AdminRoute>
    ),
    index: true,
  },
  {
    path: "admin/PricingTier",
    element: (
      <AdminRoute>
        <PricingTier />
      </AdminRoute>
    ),
    index: true,
  },
  {
    path: "admin/subscription",
    element: (
      <AdminRoute>
        <Subscription />
      </AdminRoute>
    ),
    index: true,
  },
  {
    path: "auth",
    element: (
      <PrivateRoute>
        <LayoutProfile isUserPage={false} />
      </PrivateRoute>
    ),
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "profile/customQRs",
        element: <CustomQRs />,
      },
      {
        path: "profile/shortURLs",
        element: <ShortURLs />,
      },
    ],
  },
  {
    path: "auth",
    element: (
      <PublicRoute>
        <LayoutProfile />
      </PublicRoute>
    ),
    children: [
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "signin",
        element: <Signin />,
      },
      {
        path: "forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "resetpassword/:token",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/:username",
    element: <LayoutProfile isUserPage={true} />,
    children: [
      {
        index: true,
        element: <User isUserPage={true} />,
      },
    ],
  },
  {
    path: "qr/:guid",
    element: <QR />,
    index: true,
  },
  {
    path: "cardqrviewanalytics/:guid",
    element: (
      <PrivateRoute>
        <CardQRViewAnalytics />
      </PrivateRoute>
    ),
  },
  {
    path: "qranalytics/:guid",
    element: (
      <PrivateRoute>
        <QRAnalytics />
      </PrivateRoute>
    ),
  },
  {
    path: "shorturlanalytics/:guid",
    element: (
      <PrivateRoute>
        <ShortUrlAnalytics />
      </PrivateRoute>
    ),
  },
  {
    path: "analytics",
    element: (
      <PrivateRoute>
        <Analytics />
      </PrivateRoute>
    ),
    index: true,
  },
  {
    path: "analytics/:userName",
    element: (
      <PrivateRoute>
        <Analytics />
      </PrivateRoute>
    ),
    index: true,
  },
  {
    path: "loginhistory/:userName",
    element: (
      <PrivateRoute>
        <Logins />
      </PrivateRoute>
    ),
    index: true,
  },
  {
    path: "/preview/templateOne/:cardId",
    element: (
      <PrivateRoute>
        <TemplateOne />
      </PrivateRoute>
    ),
  },
  {
    path: "/preview/templateTwo/:cardId",
    element: (
      <PrivateRoute>
        <TemplateTwo />
      </PrivateRoute>
    ),
  },
  {
    path: "/preview/templateThree/:cardId",
    element: (
      <PrivateRoute>
        <TemplateThree />
      </PrivateRoute>
    ),
  },
  {
    path: "/q/:id",
    element: <NavigateQR />,
  },
  {
    path: "/u/:id",
    element: <NavigateShortURL />,
  },
  { path: "/auth/cardbuilder", element: <Builder /> },
]);
