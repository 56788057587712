import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useAsyncCall from "../../hooks/useAsyncCall";
import { useTranslation } from "react-i18next";
import { useGetCardQuery } from "../../Redux/services/CardApi";

export default function TemplateTwo({ username }) {
  const { cardId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: cardData, error, isLoading } = useGetCardQuery(cardId);
  console.log(cardData);

  const [card, setCard] = useState(null);

  const getCard = useAsyncCall();

  const copyToClipboard = () => {
    navigate(`/auth/signup?referral=${cardId}`);
  };

  useEffect(() => {
    const url = cardId ? `/Card/GetCard/${cardId}` : `/Card/GetCard/${username}`;
    getCard.request("get", url).then(res => {
      setCard(res.data);
    });
  }, []);

  return (
    <div className=" px-5">
      {cardId && <h1>Template two</h1>}
      <div className="container-xxl mx-auto rounded-4 bg-white shadow-lg ">
        <div className="d-flex flex-column gap-2 my-4 py-4">
          <div className="d-flex justify-content-between ">
            <img
              src={`https://storage.googleapis.com/addmeimages/${card?.cardImage}`}
              alt="card"
              className="img-fluid rounded-circle "
              style={{ width: "100px", height: "100px" }}
            />
            <div className="d-flex flex-column justify-content-end ">
              <span
                onClick={copyToClipboard}
                className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
              >
                {t("referalButton")}
              </span>
            </div>
          </div>

          <div className="d-flex gap-2 ">
            {cardData?.data?.cardItemInputs?.map((input, index) => (
              <>
                {input.name === "Firstname" && (
                  <h3
                    key={index}
                    className="fs-4 "
                  >
                    {input.value}
                  </h3>
                )}
                {input.name === "Lastname" && (
                  <h3
                    key={index}
                    className="fs-4 "
                  >
                    {input.value}
                  </h3>
                )}
              </>
            ))}
          </div>
          <div className="d-flex gap-2 align-items-center ">
            {card?.cardItemInputs?.map((input, index) => (
              <>
                {input.name === "Company" && (
                  <h3
                    key={index}
                    className="small "
                  >
                    {input.value} -{" "}
                  </h3>
                )}
                {input.name === "Position" && (
                  <h5
                    key={index}
                    className="small "
                  >
                    {input.value}
                  </h5>
                )}
              </>
            ))}
          </div>
          <div className="d-flex gap-2 ">
            {card?.cardItemInputs?.map((input, index) => (
              <>
                {input.name === "Adress" && (
                  <h3
                    key={index}
                    className="small fw-light "
                  >
                    {input.value}
                  </h3>
                )}
              </>
            ))}
          </div>
          <h3 className="fs-5 mt-3 ">Contact Details</h3>
          <div className="d-flex  row">
            {card?.cardItemInputs?.map((input, index) => (
              <>
                {input.name === "Phone1" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={`tel:${input?.value}`}
                      rel="noreferrer"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt="phone"
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Email" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={`mailto:${input?.value}`}
                      rel="noreferrer"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt="email"
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Whatsapp" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={`https://wa.me/${input?.value}`}
                      rel="noreferrer"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt="phone"
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Viber" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={`viber://chat?number=${input?.value}`}
                      rel="noreferrer"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt="phone"
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Skype" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={`skype:${input?.value}?chat`}
                      rel="noreferrer"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt="phone"
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Telegram" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={`https://t.me/${input?.value}`}
                      rel="noreferrer"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt="phone"
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
              </>
            ))}
          </div>
          <h3>Website & Social Links</h3>
          <div className="d-flex row ">
            {card?.cardItemInputs?.map((input, index) => (
              <>
                {input.name === "Website" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Facebook" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                    {/* <h3
                      key={index}
                      className="small fw-light "
                    >
                      {input.value}
                    </h3> */}
                  </div>
                )}
                {input.name === "Instagram" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Twitter" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "LinkedIn" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Youtube" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Pinterest" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Tiktok" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Github" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Spotify" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Wechat" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
                {input.name === "Udemy" && (
                  <div className="d-flex  col-4 gap-2 justify-content-center align-items-center ">
                    <a
                      target="_blank"
                      href={input.value}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <img
                        src={`/${input.icon}`}
                        alt={input.value}
                        width={48}
                        height={48}
                      />
                    </a>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      {cardId && <Link to={"/auth/profile"}>Back to Profile</Link>}
    </div>
  );
}
