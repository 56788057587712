import React, { useState, useEffect } from "react";

export default function Countdown({ targetDate }) {
  const targetTime = new Date(targetDate).getTime();

  const [time, setTime] = useState(targetTime - new Date().getTime());

  const countDown = milleseconds => {
    let totalSeconds = parseInt(Math.floor(milleseconds / 1000));
    let totalMinutes = parseInt(Math.floor(totalSeconds / 60));
    let totalHours = parseInt(Math.floor(totalMinutes / 60));
    let days = parseInt(Math.floor(totalHours / 24));

    let seconds = parseInt(totalSeconds % 60);
    let minutes = parseInt(totalMinutes % 60);
    let hours = parseInt(totalHours % 24);

    return `${days} დღე ${hours} საათი ${minutes} წუთი ${seconds} წამი`;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = targetTime - new Date().getTime();
      setTime(newTimeLeft >= 0 ? newTimeLeft : 0);
    }, 1000);
    return () => clearTimeout(timer);
  }, [time]);

  return (
    <div className="">
      <span className="text-primary-gradient small border py-2 px-3 rounded-2">
        {time === targetDate ? "" : countDown(time)}
      </span>
    </div>
  );
}
