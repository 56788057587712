import { Outlet, Link } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import Share from "../../components/share/share";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import { useAuth } from "../../providers/auth/authProvider";

const Layout = ({ isUserPage }) => {
  const { t } = useTranslation();
  // const { isAuthorized } = useAuth();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="container-xxl position-relative p-0">
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <Outlet />
      <Footer />
      {/* {isAuthorized === true || isUserPage === true ? (
        <Share isUserPage={isUserPage} />
      ) : (
        ""
      )} */}
    </div>
  );
};

export default Layout;
