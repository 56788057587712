import "../../assets/share.css";
import { useEffect, useState } from "react";

import useAsyncCall from "../../hooks/useAsyncCall";
import Modal from "react-bootstrap/Modal"; // Assuming you're using Bootstrap via react-bootstrap
import { useTranslation } from "react-i18next";

import QrCode from "../../assets/qr-code-scan.png";
import DownloadButton from "../../assets/save.png";

const Share = ({ isUserPage, username }) => {
  // const username = window.location.href.split(/[/ ]+/).pop();
  const [color, setColor] = useState("");
  const [qr, setQR] = useState(false);
  const [vCard, setVCard] = useState("");
  const [link, setLink] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const getqr = useAsyncCall();
  const [notFound, setNotfound] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    let requestUrl = `/Card/GetCardQR?typeId=${isUserPage ? 2 : 1}&${
      username ? `userName=${username}` : ""
    }`;
    getqr
      .request("get", requestUrl)
      .then(e => {
        if (e.success) {
          setQR(e.data.qr);
          setLink(e.data.link);
          setVCard(e.data.vCard);
        } else if (e.errorCode === 3) {
          setNotfound(true);
        } else if (e.errorCode === 8) {
          setIsAnonymous(true);
        }
      })
      .finally(setLoading(false));
  }, [isUserPage]);
  useEffect(() => {
    if (username.toLocaleLowerCase() === "agilementor") {
      setColor("#FDD835");
    } else if (username.toLocaleLowerCase() === "tradershub") {
      setColor("#9D1C1F");
    } else if (username.toLocaleLowerCase() === "bc463") {
      setColor("#ec7345");
    } else {
      setColor("#fff");
    }
  }, [username]);

  const handleDownload = () => {
    var blob = new Blob([vCard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);
    const newLink = document.createElement("a");
    newLink.download = "vCard.vcf";
    newLink.href = url;

    // Append link to the body temporarily to trigger the download
    document.body.appendChild(newLink);
    newLink.click();
    document.body.removeChild(newLink); // Clean up
    return vCard;
  };

  if (!loading && notFound) {
    return "";
  }

  if (!loading && isAnonymous) {
    return "";
  }

  return (
    <>
      <div
        className="text-center d-flex justify-content-center align-items-center position-fixed sticky-bottom w-100 "
        style={{
          backgroundColor: color,
          gap: "5rem",
          left: "0px",
          boxShadow:
            username === "agilementor"
              ? "0px 0px 10px 0px #6966EE"
              : username === "tradershub"
              ? "0px 0px 10px 0px #6966EE"
              : username === "bc463"
              ? "0px 0px 10px 0px #6966EE"
              : "none",
        }}
      >
        {username === "agilementor" ? (
          <div
            className="border-top d-none"
            style={{
              width: "100vw",
              position: "absolute",
              top: "0",
            }}
          ></div>
        ) : (
          <div
            className="border-top d-md-none"
            style={{
              width: "100vw",
              position: "absolute",
              top: "0",
            }}
          ></div>
        )}
        <button
          className="share-item py-2 px-3"
          onClick={() => setShowModal(true)}
        >
          <img
            src={QrCode}
            alt=""
            className="img-fluid "
            width={54}
            height={54}
          />
        </button>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <img
                src={qr}
                width={250}
                height={250}
                alt="QR Code"
              />
              <a
                className="d-block"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </a>
            </div>
          </Modal.Body>
        </Modal>
        {isUserPage && (
          <button
            className="share-item py-2 px-3 "
            onClick={() => handleDownload()}
            // id="download"
          >
            <img
              src={DownloadButton}
              alt=""
              className="img-fluid "
              width={50}
              height={50}
            />
          </button>
        )}
        <div
          className="border-top d-md-none"
          style={{
            width: "100vw",
            position: "absolute",
            bottom: "0",
          }}
        ></div>
      </div>
    </>
  );
};

export default Share;
