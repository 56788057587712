import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiOutlineQrCode } from "react-icons/hi2";
import { FaLink } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import {
  useUpdateCardMutation,
  useChangeUsernameMutation,
} from "../../../Redux/services/CardApi";
import Countdown from "../../../components/countdown/countdown";
import { setToastNotification } from "../../../Redux/slices/ToastNotificationSlice";
import { useDispatch } from "react-redux";

export default function PremiumFeatureCard({
  selectedCardIndex,
  activeCard,
  activeCardId,
}) {
  const [foldedPremiumCard, setFoldedPremiumCard] = useState(true);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [updateCard] = useUpdateCardMutation();
  const [changeUsername] = useChangeUsernameMutation();
  const [newUsername, setNewUsername] = useState("");
  const { t } = useTranslation();
  const { user, cards } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const templates = [
    { id: 0, name: "Default", url: "" },
    {
      id: 1,
      name: "Template One",
      url: `/preview/templateThree/${activeCard?.userName}`,
    },
    {
      id: 2,
      name: "Template Two",

      url: `/preview/templateTwo/${activeCard?.userName}`,
    },
  ];

  const visibleCards = cards?.slice(0, user?.pricingPlan?.cardLimit);

  const toggleFoldPremiumCard = () => setFoldedPremiumCard(prev => !prev);
  const handleNewUsernameValue = e => setNewUsername(e.target.value);
  const handleCardTemplate = async templateId => {
    const template = {
      CardId: activeCardId,
      TemplateId: templateId,
    };

    const response = await updateCard(template).unwrap();
    try {
      if (response.success) {
        dispatch(
          setToastNotification({
            message: "Template Changed Successfully",
            type: "success",
          })
        );
      } else {
        dispatch(
          setToastNotification({
            message: "Something went wrong",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  const handleSendNewUsername = async () => {
    const response = await changeUsername({
      cardId: activeCardId,
      name: newUsername,
    });
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: t("usernameChangeSuccess"),
            type: "success",
          })
        );

        setNewUsername("");
        setShowUsernameModal(false);
      }
      if (response.data.errorCode === 9) {
        dispatch(
          setToastNotification({
            message: t("usernameError"),
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  return (
    <div className="card mb-4 ">
      <div
        className="card-header text-primary-gradient "
        onClick={toggleFoldPremiumCard}
        style={{ cursor: "pointer" }}
      >
        {t("premiumFeatures")}
      </div>
      <div className="card-body">
        <div style={{ display: foldedPremiumCard ? "none" : "block" }}>
          <div className="row justify-content-end">
            {user?.pricingPlan?.canAnalytics && (
              <>
                <div className=" col-md-4 col-12 align-items-center d-flex  justify-content-center  mb-3 ">
                  <Link
                    to={`/analytics/${cards[selectedCardIndex]?.guid}`}
                    className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
                  >
                    {t("analytics")}
                  </Link>
                </div>
                <div className=" col-md-4 col-12 align-items-center d-flex  justify-content-center  mb-3 ">
                  <Link
                    to={`/cardqrviewanalytics/${cards[selectedCardIndex]?.guid}?typeId=1`}
                    className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
                  >
                    {t("type1QR")}
                  </Link>
                </div>
                <div className=" col-md-4 col-12 align-items-center d-flex  justify-content-center  mb-3 ">
                  <Link
                    to={`/cardqrviewanalytics/${cards[selectedCardIndex]?.guid}?typeId=2`}
                    className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
                  >
                    {t("type2QR")}
                  </Link>
                </div>
              </>
            )}
            {user?.pricingPlan?.qrLimit > 0 && (
              <>
                <div className=" col-6 col-md-2 align-items-center d-flex justify-content-center  mb-3">
                  <Link
                    to="/auth/profile/customQRs"
                    className="btn btn-primary-gradient rounded-pill py-2 px-3 ms-3 me-4"
                  >
                    <HiOutlineQrCode />
                  </Link>
                </div>
              </>
            )}

            {user?.pricingPlan?.shortLinkLimit > 0 && (
              <>
                <div className=" col-6 col-md-2 align-items-center d-flex justify-content-center  mb-3  ">
                  <Link
                    to={"/auth/profile/shortURLs"}
                    className="btn btn-primary-gradient rounded-pill py-2 px-3    ms-3 me-4"
                  >
                    <FaLink />
                  </Link>
                </div>
              </>
            )}

            {user?.pricingPlan?.canChangeUsername && (
              <div className=" col-md-8 col-12 align-items-center d-flex justify-content-center  mb-3  ">
                <button
                  className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
                  onClick={() => setShowUsernameModal(true)}
                >
                  {t("changeUsername")}
                </button>
              </div>
            )}

            {user?.pricingPlan?.canChooseTemplate && (
              <>
                {templates?.map(template => (
                  <div className=" col-md-6 col-12 align-items-center d-flex gap-3  justify-content-center  mb-3  ">
                    <button
                      className={`btn btn-primary-gradient 
                  ${
                    visibleCards[selectedCardIndex]?.templateId === template.id
                      ? "active"
                      : ""
                  }`}
                      onClick={() => handleCardTemplate(template.id)}
                    >
                      {visibleCards[selectedCardIndex || 0]?.templateId ===
                      template.id
                        ? `${template.name} is selected`
                        : `Choose ${template.name}`}
                    </button>
                    {template?.id !== 0 && <Link to={template.url}>Preview</Link>}
                  </div>
                ))}
              </>
            )}
            <div className="col-md-6 col-12 d-flex flex-column gap-2 align-items-center justify-content-center">
              <div className="d-flex flex-md-row flex-column align-content-center justify-content-center gap-2">
                <h5 className="small">{t("active")} :</h5>
                <Countdown
                  targetDate={user?.pricingPlan?.userPricingPlans[0]?.expireDate}
                />
              </div>
              <div className="d-flex gap-2 align-content-center justify-content-center">
                <h5 className="small">Plan Name : </h5>
                <h5 className="text-primary-gradient small">
                  {user?.pricingPlan?.name}
                </h5>
              </div>
            </div>

            <Modal
              onHide={() => setShowUsernameModal(false)}
              show={showUsernameModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <div className="d-flex justify-content-center align-items-center gap-3 ">
                  <div className="d-flex flex-column gap-3 ">
                    <h6 className="fs-5">
                      {t("cardName")} - {activeCard?.cardName}
                    </h6>
                    <h6 className="small">{t("changeUsername")}</h6>

                    <input
                      type="text"
                      id="newUsername"
                      name="newUsername"
                      value={newUsername}
                      onChange={handleNewUsernameValue}
                      className="form-control"
                      placeholder={t("usernamePlaceholder")}
                    />
                  </div>
                  <button
                    className="btn btn-primary-gradient align-self-end "
                    onClick={handleSendNewUsername}
                  >
                    {t("send")}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
