import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useUpdateProfileMutation,
  useDeactivateAccountMutation,
} from "../../../Redux/services/UserApi";
import { useSelector, useDispatch } from "react-redux";
import { signOutSuccess } from "../../../Redux/slices/UserSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/form/input";
import Button from "../../../components/ui/button";
import { setToastNotification } from "../../../Redux/slices/ToastNotificationSlice";

export default function AccountDetails() {
  const methodsOnSubmit = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [folded, setFolded] = useState(true);
  const user = useSelector(state => state.user.user);
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const [deactivateAccount] = useDeactivateAccountMutation();

  const onSubmit = methodsOnSubmit.handleSubmit(async data => {
    const response = await updateProfile(data);

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: t("passwordChangeSuccess"),
            type: "success",
          })
        );

        methodsOnSubmit.reset();
      } else {
        if (!response.data.success) {
          dispatch(
            setToastNotification({
              message: t("incorrectPassword"),
              type: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  });

  const handleDeactivateProfile = async () => {
    const response = await deactivateAccount();

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: "Account deactivated successfully",
            type: "success",
          })
        );
        dispatch(signOutSuccess());
        navigate("/");
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  const toggleFold = () => setFolded(prev => !prev);

  return (
    <div className="card mb-4">
      <div
        className="card-header"
        onClick={toggleFold}
        style={{ cursor: "pointer" }}
      >
        {t("accountDetails")}
      </div>
      <div className="card-body">
        <div style={{ display: folded ? "none" : "block" }}>
          <FormProvider {...methodsOnSubmit}>
            <form
              onSubmit={e => e.preventDefault()}
              noValidate
              autoComplete="off"
              className="container"
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    {...{
                      name: "Firstname",
                      type: "text",
                      id: "firstname",
                      value: user?.firstname,
                      placeholder: t("signUpFirstname"),
                      validation: {
                        required: {
                          value: true,
                          message: t("signUpFirstnameRequired"),
                        },
                        maxLength: {
                          value: 30,
                          message: t("maximumCharactersAllowed", {
                            length: 30,
                          }),
                        },
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    {...{
                      name: "Lastname",
                      type: "text",
                      id: "lastname",
                      value: user?.lastname,
                      placeholder: t("signUpLastname"),
                      validation: {
                        required: {
                          value: true,
                          message: t("signUpLastnameRequired"),
                        },
                        maxLength: {
                          value: 30,
                          message: t("maximumCharactersAllowed", {
                            length: 30,
                          }),
                        },
                      },
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    {...{
                      name: "Email",
                      type: "email",
                      id: "email",
                      value: user?.email,
                      placeholder: t("signUpEmail"),
                      disabled: true,
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    {...{
                      name: "CurrentPassword",
                      type: "password",
                      id: "currentPassword",
                      placeholder: t("profileCurrentPassword"),
                      showPasswordToggle: true,
                      validation: {
                        minLength: {
                          value: 6,
                          message: t("signUpPasswordLenghtRequired", {
                            length: 6,
                          }),
                        },
                        pattern: {
                          message: t("signUpPasswordSpaceNotAllowed"),
                          value: /^\S*$/,
                        },
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    {...{
                      name: "Password",
                      type: "password",
                      id: "password",
                      placeholder: t("signUpPassword"),
                      showPasswordToggle: true,
                      compareFrom: {
                        key: "ConfirmPassword",
                        message: t("signUpPasswordsDontMatch"),
                      },
                      validation: {
                        minLength: {
                          value: 6,
                          message: t("signUpPasswordLenghtRequired", {
                            length: 6,
                          }),
                        },
                        pattern: {
                          message: t("signUpPasswordSpaceNotAllowed"),
                          value: /^\S*$/,
                        },
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    {...{
                      name: "ConfirmPassword",
                      type: "password",
                      id: "confirmPassword",
                      placeholder: t("signUpConfirmPassword"),
                      showPasswordToggle: true,
                      compareTo: {
                        key: "Password",
                        message: t("signUpPasswordsDontMatch"),
                      },
                    }}
                  />
                </div>
              </div>

              <div className="mt-5">
                <Button
                  func={onSubmit}
                  text={t("saveProfileDetails")}
                  isLoading={isLoading}
                />
                <div className="align-items-center d-flex justify-content-end  my-3 ">
                  <button
                    className="btn btn-danger rounded-pill py-2 px-4 ms-3 me-4"
                    onClick={handleDeactivateProfile}
                  >
                    {t("deactivateProfile")}
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
