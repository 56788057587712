import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <div
      className="container-xxl py-5"
      id="pricing"
    >
      <div className="container py-5 px-lg-5">
        <div
          className="text-center wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <h5 className="text-primary-gradient fw-medium">{t("pricingTitle")}</h5>
          <h1 className="mb-5">{t("pricingSubTitle")}</h1>
        </div>
        <div
          className="tab-class text-center pricing wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <ul className="nav nav-pills d-inline-flex justify-content-center bg-primary-gradient rounded-pill mb-5">
            <li className="nav-item">
              <button
                className="nav-link active"
                data-bs-toggle="pill"
                href="#tab-1"
              >
                {t("pricingPersonal")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                data-bs-toggle="pill"
                href="#tab-2"
              >
                {t("pricingBusiness")}
              </button>
            </li>
          </ul>
          <div className="tab-content text-start">
            <div
              id="tab-1"
              className="tab-pane fade show p-0 active"
            >
              <div className="row g-4">
                <div className="col-lg-4">
                  <div className="bg-light rounded">
                    <div className="border-bottom p-4 mb-4">
                      <h4 className="text-primary-gradient mb-1">
                        {t("pricingPlan1Title")}
                      </h4>
                      <span>{t("pricingPlan1SubTitle")}</span>
                    </div>
                    <div className="p-4 pt-0">
                      <h1 className="mb-3">
                        <small
                          className="align-top"
                          style={{ "font-size": "22px", "line-height": "45px" }}
                        >
                          ₾
                        </small>
                        {t("pricingPlan1Price")}
                      </h1>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan1Feature1")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan1Feature2")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan1Feature3")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <Link
                        to=""
                        className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                      >
                        {t("pricingGetStarted")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="bg-light rounded border">
                    <div className="border-bottom p-4 mb-4">
                      <h4 className="text-primary-gradient mb-1">
                        {t("pricingPlan2Title")}
                      </h4>
                      <span>{t("pricingPlan2SubTitle")}</span>
                    </div>
                    <div className="p-4 pt-0">
                      <h1 className="mb-3">
                        <small
                          className="align-top"
                          style={{ "font-size": "22px", "line-height": "45px" }}
                        >
                          ₾
                        </small>
                        {t("pricingPlan2Price")}
                      </h1>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan2Feature1")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan2Feature2")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan2Feature3")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan2Feature4")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan2Feature5")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <Link
                        to=""
                        className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                      >
                        {t("pricingGetStarted")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="bg-light rounded border">
                    <div className="border-bottom p-4 mb-4">
                      <h4 className="text-primary-gradient mb-1">
                        {t("pricingPlan3Title")}
                      </h4>
                      <span>{t("pricingPlan3SubTitle")}</span>
                    </div>
                    <div className="p-4 pt-0">
                      <h1 className="mb-3">
                        <small
                          className="align-top"
                          style={{ "font-size": "22px", "line-height": "45px" }}
                        >
                          ₾
                        </small>
                        {t("pricingPlan3Price")}
                      </h1>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan3Feature1")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan3Feature2")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan3Feature3")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan3Feature4")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingPlan3Feature5")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <Link
                        to=""
                        className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                      >
                        {t("pricingGetStarted")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tab-2"
              className="tab-pane fade p-0"
            >
              <div className="row g-4">
                <div className="col-lg-4">
                  <div className="bg-light rounded">
                    <div className="border-bottom p-4 mb-4">
                      <h4 className="text-primary-gradient mb-1">
                        {t("pricingBusinessPlan1Title")}
                      </h4>
                      <span>{t("pricingBusinessPlan1SubTitle")}</span>
                    </div>
                    <div className="p-4 pt-0">
                      <h1 className="mb-3">
                        <small
                          className="align-top"
                          style={{ "font-size": "22px", "line-height": "45px" }}
                        >
                          ₾
                        </small>
                        {t("pricingBusinessPlan1Price")}
                      </h1>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan1Feature1")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan1Feature2")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan1Feature3")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan1Feature4")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <Link
                        to=""
                        className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                      >
                        {t("pricingGetStarted")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="bg-light rounded border">
                    <div className="border-bottom p-4 mb-4">
                      <h4 className="text-primary-gradient mb-1">
                        {t("pricingBusinessPlan2Title")}
                      </h4>
                      <span>{t("pricingBusinessPlan2SubTitle")}</span>
                    </div>
                    <div className="p-4 pt-0">
                      <h1 className="mb-3">
                        <small
                          className="align-top"
                          style={{ "font-size": "22px", "line-height": "45px" }}
                        >
                          ₾
                        </small>
                        {t("pricingBusinessPlan2Price")}
                      </h1>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan2Feature1")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan2Feature2")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan2Feature3")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan2Feature4")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <Link
                        to=""
                        className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                      >
                        {t("pricingGetStarted")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="bg-light rounded border">
                    <div className="border-bottom p-4 mb-4">
                      <h4 className="text-primary-gradient mb-1">
                        {t("pricingBusinessPlan3Title")}
                      </h4>
                      <span>{t("pricingBusinessPlan3SubTitle")}</span>
                    </div>
                    <div className="p-4 pt-0">
                      <h1 className="mb-3">
                        <small
                          className="align-top"
                          style={{ "font-size": "22px", "line-height": "45px" }}
                        >
                          ₾
                        </small>
                        {t("pricingBusinessPlan3Price")}
                      </h1>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan3Feature1")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan3Feature2")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan3Feature3")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <span>{t("pricingBusinessPlan3Feature4")}</span>
                        <i className="fa fa-check text-primary-gradient pt-1"></i>
                      </div>
                      <Link
                        to=""
                        className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4"
                      >
                        {t("pricingGetStarted")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
