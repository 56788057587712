import React, { useState, useEffect } from "react";

import AgileMentor from "../../components/premium/AgileMentor";
import Navbar from "../../components/navbar/navbar";
import TemplateOne from "../../components/templates/templateOne";
import TemplateTwo from "../../components/templates/templateTwo";
import TradersHub from "../../components/premium/TradersHub";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Share from "../../components/share/share";
import { useLazyGetCardQuery } from "../../Redux/services/CardApi";
import { useNavigateToCustomQRURLQuery } from "../../Redux/services/UserApi";

const User = ({ isUserPage }) => {
  const { username } = useParams();
  const { isAuthorized } = useSelector(state => state.user);

  const [trigger, { data: card, isSuccess, isError, isLoading }] =
    useLazyGetCardQuery();
  const { data: customQrLink } = useNavigateToCustomQRURLQuery(username);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (customQrLink?.success === false) {
      trigger(username);
    }
  }, [customQrLink?.success, trigger, username]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const copyToClipboard = () => {
    navigate(`/auth/signup?referral=${username}`);
  };

  const renderContactIcon = (item, index) => {
    const urlMap = {
      Phone1: `tel:${item.value}`,
      Phone2: `tel:${item.value}`,
      Website: item.value,
      Whatsapp: `https://wa.me/${item.value}`,
      Wechat: `weixin://dl/chat?${item.value}`,
      Skype: `skype:${item.value}?chat`,
      Telegram: `https://t.me/${item.value}`,
      Viber: `viber://chat/?number=${item.value}`,
      Email: `mailto:${item.value}`,
      Instagram: item.value,
      Facebook: item.value,
      Github: item.value,
      Pinterest: item.value,
      Spotify: item.value,
      Tiktok: item.value,
      Youtube: item.value,
      Udemy: item.value,
      Linkedin: item.value,
      Twitter: item.value,
    };

    const href = urlMap[item.name] || item.value;

    return (
      <div
        className="col-4"
        key={index}
      >
        <a
          target="_blank"
          href={href}
          rel="noreferrer"
        >
          <img
            src={item.icon}
            width={48}
            height={48}
            alt={`${item.name} icon`}
          />
        </a>
      </div>
    );
  };

  if (card?.data === null) {
    return (
      <>
        <Navbar
          links={[
            {
              name: t("menuHome"),
              url: "home",
              notActive: true,
            },
            {
              name: t("menuAbout"),
              url: "about",
            },
            {
              name: t("menuFeature"),
              url: "feature",
            },
            {
              name: t("menuPricing"),
              url: "pricing",
            },
            // {
            //   name: t("menuReview"),
            //   url: "review",
            // },
            {
              name: t("menuContact"),
              url: "contact",
            },
          ]}
        />
        <div className="d-flex justify-content-center p-5 text-center mt-lg-5 ">
          {" "}
          {isLoading && (
            <div
              className="spinner-border"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          {!isLoading && <h1>{t("userNotFound")}</h1>}
        </div>
      </>
    );
  }
  if (card?.data?.deactivated) {
    return (
      <>
        <Navbar
          links={[
            {
              name: t("menuHome"),
              url: "home",
              notActive: true,
            },
            {
              name: t("menuAbout"),
              url: "about",
            },
            {
              name: t("menuFeature"),
              url: "feature",
            },
            {
              name: t("menuPricing"),
              url: "pricing",
            },
            // {
            //   name: t("menuReview"),
            //   url: "review",
            // },
            {
              name: t("menuContact"),
              url: "contact",
            },
          ]}
        />
        <div className="d-flex justify-content-center p-5 mt-lg-5 text-center">
          {" "}
          <h1>Card is Deactivated</h1>{" "}
        </div>
      </>
    );
  }

  if (card?.data?.isAnonymous) {
    return (
      <>
        <Navbar
          links={[
            {
              name: t("menuHome"),
              url: "home",
              notActive: true,
            },
            {
              name: t("menuAbout"),
              url: "about",
            },
            {
              name: t("menuFeature"),
              url: "feature",
            },
            {
              name: t("menuPricing"),
              url: "pricing",
            },
            // {
            //   name: t("menuReview"),
            //   url: "review",
            // },
            {
              name: t("menuContact"),
              url: "contact",
            },
          ]}
        />
        <div className="d-flex justify-content-center p-5 mt-lg-5 text-center">
          {" "}
          <h1>{t("cardIsAnonymous")}</h1>{" "}
        </div>
      </>
    );
  }

  return (
    <>
      {username?.toLocaleLowerCase() === "agilementor" ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <AgileMentor isUserPage={isUserPage} />
        </div>
      ) : (
        <>
          <Navbar
            links={[
              {
                name: t("menuHome"),
                url: "/",
                notActive: true,
              },
              {
                name: t("menuAbout"),
                url: "/about",
              },
              {
                name: t("menuFeature"),
                url: "/feature",
              },
              {
                name: t("menuPricing"),
                url: "/pricing",
              },
              // {
              //   name: t("menuReview"),
              //   url: "/review",
              // },
              {
                name: t("menuContact"),
                url: "/contact",
              },
            ]}
          />
          <div className="d-flex flex-column gap-2 justify-content-md-center position-relative px-5 mt-md-5 pt-md-5 text-center mt-2 pt-2 mb-5 pb-5">
            <div className="col-auto">
              <span
                onClick={copyToClipboard}
                className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 me-4"
              >
                {t("referalButton")}
              </span>
            </div>

            <div className="row pt-2 pt-md-3 ">
              {card?.data?.cardImage && (
                <div className="col-12">
                  <img
                    className="img-account-profile rounded-circle mb-2"
                    width={200}
                    height={200}
                    src={`https://storage.googleapis.com/addmeimages/${card?.data?.cardImage}`}
                    alt=""
                  />
                </div>
              )}
              {/* ცალკე იმიტომ ვეძებ სახელს და გვარს რო გვერდიგვერდ დაწერა შემეძლოს და არა ცალ ცალკე დივებად */}
              {card?.data?.cardItemInputs.find(x => x.name === "Firstname") &&
                card?.data?.cardItemInputs.find(x => x.name === "Lastname") && (
                  <div className="col-12">
                    {
                      card.data.cardItemInputs.find(x => x.name === "Firstname")
                        ?.value
                    }{" "}
                    {
                      card.data.cardItemInputs.find(x => x.name === "Lastname")
                        ?.value
                    }
                  </div>
                )}
              {/* აქ ცალ ცალკე დივებად ვწერ მისამართს კომპანიას და პოზიციას, თუ აითემ ნეიმი სახელი ან გვარი იქნება მაშინ ნალს იმიტორო ზემოთ უკვე წერია */}
              {card?.data?.cardItemInputs.map((item, index) => {
                if (["Adress", "Company", "Position"].includes(item.name)) {
                  return (
                    <div
                      className="col-12"
                      key={index}
                    >
                      {item.value}
                    </div>
                  );
                }
                if (["Firstname", "Lastname"].includes(item.name)) {
                  return null;
                }
                {
                  /*დაარენდერებს ყველა აიქონს*/
                }
                return renderContactIcon(item, index);
              })}
            </div>
          </div>
        </>
      )}
      <div className="mt-5 pt-5 position-fixed sticky-bottom ">
        {/* {isAuthorized === true || isUserPage === true ? ( */}
        <Share
          isUserPage={isUserPage}
          username={username}
        />
        {/* ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default User;
