import { useState } from "react";
import Modal from "../../components/image/base/modal";
import { readFile } from "../../helpers/cropImage";
import ImageCropModalContent from "./imageCropModalContent";
import { useImageCropContext } from "../../providers/imageCropProvider";
import { useTranslation } from "react-i18next";

const ImageCrop = ({ image, width, height, classes, showChange, onChange, id }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [preview, setPreview] = useState(image);

  const { getProcessedImage, setImage, resetStates } = useImageCropContext();

  const handleDone = async () => {
    const avatar = await getProcessedImage();

    if (avatar.size / 1024 / 1024 < 2) {
      try {
        setPreview(window.URL.createObjectURL(avatar));
        resetStates();

        onChange(avatar, id);
        setOpenModal(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Image size should be less than 2MB");
    }
  };

  const handleClose = () => {
    resetStates();
    setOpenModal(false);
    // setImage(null);
  };

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];

    if (file) {
      const imageDataUrl = await readFile(file);
      setImage(imageDataUrl);
      setPreview(imageDataUrl);
      setOpenModal(true);
    } else {
      resetStates();
      setImage(null);
    }
  };

  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <input
        type="file"
        onChange={handleFileChange}
        className="hidden"
        id={id}
        accept="image/*"
      />
      <label
        htmlFor={id}
        className={`${showChange && "profile-pic position-relative"} ${classes}`}
      >
        <img
          src={preview ?? image}
          height={height}
          width={width}
          className={`object-cover rounded-full h-48 w-48 ${classes}`}
          alt=""
        />
        {showChange && (
          <div className="profilepic-content">
            <span className="profilepic-icon">
              <i className="fas fa-camera"></i>
            </span>
            <span className="profilepic-text w-100 ">{t("uploadImage")}</span>
          </div>
        )}
      </label>

      <Modal
        open={openModal}
        handleClose={handleClose}
      >
        <ImageCropModalContent
          id={id}
          handleDone={handleDone}
          handleClose={handleClose}
        />
      </Modal>
    </div>
  );
};

export default ImageCrop;
