import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useEffect, useState } from "react";
import useAsyncCall from "../../hooks/useAsyncCall";
import { useTranslation } from "react-i18next";

const LanguagePicker = ({ className, fontSize }) => {
  const getLanguages = useAsyncCall();
  const [languages, setLanguages] = useState([]);

  const [showDropdown, setShowDropdown] = useState("");
  const [chosenLanguage, setChosenLanguage] = useState({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getLanguages.request("get", "Language/GetLanguages").then(e => {
      const result = e.data;
      const userLanguage = localStorage.language;

      setLanguages(e.data);
      let language = result.find(x => x.languageCode === userLanguage);
      if (language) {
        setChosenLanguage(language);
      } else {
        language = result.find(x => x.isDefault);
        setChosenLanguage(language);
      }
      localStorage.setItem("language", language.languageCode);
      i18n.changeLanguage(language.languageCode).then(r => []);
    });
  }, []);

  const setDropdownState = () => {
    setShowDropdown(showDropdown === "show" ? "" : "show");
  };
  const closeDropdown = e => {
    if (!e.target.className.includes("chosen-language")) {
      setShowDropdown("");
    }
  };
  useEffect(() => {
    window.addEventListener("click", closeDropdown);

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  }, []);

  const chooseLanguage = language => {
    localStorage.setItem("language", language.languageCode);
    setChosenLanguage(language);
    i18n.changeLanguage(language.languageCode).then(r => []);
  };

  return (
    <div
      className={`dropdown language-picker ${showDropdown} ${
        className ? className : ""
      }`}
    >
      <span
        className="dropdown-toggle"
        id="Dropdown"
        role="button"
        data-mdb-toggle="dropdown"
        aria-expanded="false"
        onClick={setDropdownState}
      >
        <i
          className={`fi fi-${chosenLanguage.icon} m-0 chosen-language border `}
          style={{ fontSize: fontSize }}
        ></i>
      </span>

      <ul
        className={`dropdown-menu dropdown-menu-end ${showDropdown}`}
        aria-labelledby="Dropdown"
        // style={{
        //   left: "-124px",
        //   top: "50px",
        // }}
      >
        <li>
          <span className="dropdown-item">
            <i className={`fi fi-${chosenLanguage.icon}`}></i> {chosenLanguage.name}{" "}
            <i className="fa fa-check text-success ms-2"></i>
          </span>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        {languages.map((language, index) => {
          return language.languageCode === chosenLanguage.languageCode ? (
            ""
          ) : (
            <li key={index}>
              <span
                className="dropdown-item"
                role="button"
                onClick={() => chooseLanguage(language)}
              >
                <i className={`fi fi-${language.icon}`}></i> {language.name}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LanguagePicker;
