import LanguagePicker from "../selectors/languagePicker";
import Logo from "../../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useLogout from "../../hooks/useLogout";

const Navbar = ({ links }) => {
  const { isAuthorized } = useSelector(state => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleLogOut = useLogout();

  function openNavbar() {
    let nav = document.getElementById("navbarCollapse");
    nav.classList.toggle("show");
  }

  const handleNavClick = sectionId => {
    if (window.location.pathname !== "/") {
      navigate("/");
      setTimeout(() => scrollToSection(sectionId), 100);
    } else {
      scrollToSection(sectionId);
    }
  };

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 sticky-top  `}
      >
        <Link
          to="/"
          className="navbar-brand p-0 d-lg-none "
        >
          <img
            alt=""
            src={Logo}
            width="120"
            height="27"
            className="d-inline-block align-top"
            id="home"
          />
        </Link>
        <Link
          to="/"
          className="navbar-brand p-0c d-none d-lg-block"
        >
          <img
            alt=""
            src={Logo}
            width="200"
            height="45"
            className="d-inline-block align-top"
            id="home"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          onClick={openNavbar}
        >
          <span className="fa fa-bars"></span>
        </button>
        <div
          className="collapse navbar-collapse navbar-collapse-menu"
          id="navbarCollapse"
        >
          {isAuthorized ? (
            <div className="d-block d-lg-none mt-2 ">
              <Link
                to="/auth/profile"
                className="btn btn-primary-gradient rounded-pill py-2 px-3 d-lg-block"
              >
                {t("navbarProfile")}
              </Link>

              <button
                style={{ marginRight: "15px" }}
                onClick={handleLogOut}
                className="btn btn-danger rounded-pill py-2 px-4 ms-3 d-lg-block"
              >
                {t("navbarLogout")}
              </button>
            </div>
          ) : (
            <Link
              to="/auth/signin"
              style={{ marginRight: "15px" }}
              className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-3 d-lg-none"
            >
              {t("signInButton")}
            </Link>
          )}
          <div className="navbar-nav mx-auto py-0">
            {links.map((link, index) => {
              return (
                <span
                  onClick={() => handleNavClick(link.url)}
                  key={index}
                  className={`nav-item nav-link ${
                    index === 0 && link.notActive !== true ? "active" : ""
                  }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {link.name}
                </span>
              );
            })}
          </div>
          {isAuthorized ? (
            <div className="d-none d-lg-flex ">
              <Link
                to="/auth/profile"
                className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-lg-block"
              >
                {t("navbarProfile")}
              </Link>

              <button
                style={{ marginRight: "15px" }}
                onClick={handleLogOut}
                className="btn btn-danger rounded-pill py-2 px-4 ms-3 d-lg-block"
              >
                {t("navbarLogout")}
              </button>
            </div>
          ) : (
            <div className="d-none d-lg-block ">
              <Link
                to="/auth/signin"
                style={{ marginRight: "15px" }}
                className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-lg-block"
              >
                {t("signInButton")}
              </Link>
            </div>
          )}
          <LanguagePicker fontSize={24} />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
