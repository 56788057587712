import { useNavigate, useParams } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import { useState, useEffect } from "react";
import AgileLogo from "../../assets/AgileLogo.png";
import AddmeLogo from "../../assets/logoUpdated.png";

import AddmeWaveLogoWhite from "../../assets/addmewavelogowhite.png";
import AgileBanner from "../../assets/tkteventagile-02.jpg";
import PromoCode from "../../assets/promocode.png";

import Mariam from "../../assets/agilementor_speakers/agile_b.jpeg";
import KhatiaDzadzua from "../../assets/agilementor_speakers/xatiadzadzua_cropped_b.jpeg";
import AleksandreGhviniashvili from "../../assets/agilementor_speakers/aleksandreghviniashvili_b.jpeg";
import AnaJikia from "../../assets/agilementor_speakers/anajikia_cropped_b.jpeg";
import GigiShengelia from "../../assets/agilementor_speakers/gigishengellia_b.jpeg";
import Jemal from "../../assets/agilementor_speakers/jemal_b.jpeg";
import LevanKokaia from "../../assets/agilementor_speakers/levankokaia_cropped_b.jpeg";
import LiliaShegeeva from "../../assets/agilementor_speakers/liliashegeeva_b.jpeg";
import MariamPilipia from "../../assets/agilementor_speakers/mariampilipia_b.jpeg";
import NinoRevishvili from "../../assets/agilementor_speakers/ninorevishvili_cropped_b.jpeg";
import "../../Fonts/fonts.css";
import Share from "../share/share";

const contactInfo = [
  {
    icon: <FaYoutube />,
    link: "https://www.youtube.com/@Agile__Mentor",
    className: "px-2",
    style: {
      // fontSize: "20px",
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      backgroundColor: "#FF0000",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <FaFacebook />,
    link: "https://www.facebook.com/agilementorgeorgia?mibextid=LQQJ4d",
    className: "bg-primary",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <FaLinkedin />,
    link: "https://www.linkedin.com/company/agilementor",
    className: "bg-primary",
    style: {
      width: "32px",
      height: "32px",
      color: "#fff",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <TbWorldWww />,
    link: "https://www.agilementor.ge/",
    className: "bg-white",
    style: {
      width: "32px",
      height: "32px",
      color: "#000",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
  {
    icon: <MdOutlineMailOutline />,
    link: "mailto:info@agilementor.ge",
    className: "bg-white",
    style: {
      width: "32px",
      height: "32px",
      color: "#000",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  },
];

const targetTime = new Date("2024-04-26T00:00:00").getTime();
// console.log(targetTime);
export default function AgileMentor({ isUserPage }) {
  const [time, setTime] = useState(targetTime - new Date().getTime());
  let { username } = useParams();
  const navigate = useNavigate();
  const countDown = milleseconds => {
    let totalSeconds = parseInt(Math.floor(milleseconds / 1000));
    let totalMinutes = parseInt(Math.floor(totalSeconds / 60));
    let totalHours = parseInt(Math.floor(totalMinutes / 60));
    let days = parseInt(Math.floor(totalHours / 24));

    let seconds = parseInt(totalSeconds % 60);
    let minutes = parseInt(totalMinutes % 60);
    let hours = parseInt(totalHours % 24);

    return `${days} დღე ${hours} საათი ${minutes} წუთი ${seconds} წამი`;
  };

  const copyToClipboard = () => {
    navigate(`/auth/signup?referral=${username}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = targetTime - new Date().getTime();
      setTime(newTimeLeft >= 0 ? newTimeLeft : 0);
    }, 1000);
    return () => clearTimeout(timer);
  }, [time]);

  return (
    <div
      className="d-flex flex-column  "
      style={{
        fontFamily: "georgian_normal",
        backgroundColor: "#FDD835",
      }}
    >
      <header
        className="d-flex flex-row gap-2  mx-3 mt-2   align-items-md-center position-fixed  "
        style={{
          width: "100vw",
          backgroundColor: "#FDD835",
          left: "-16px",
          top: "-8px",
          padding: " 5px",
          boxShadow: "0px 0px 10px 0px #6966EE",
        }}
      >
        <img
          src={AgileLogo}
          alt=""
          className="logo  d-none d-md-block"
          style={{ width: "200px", height: "50px" }}
        />

        <figure className="d-none d-md-flex gap-md-1 align-items-md-center position-relative">
          <div className="position-absolute ">
            <div
              className="position-absolute   align-items-md-center d-md-flex "
              style={{
                width: "200px",
                top: "-35px",
              }}
            >
              <a
                className="text-decoration-none "
                target="_blank"
                href="https://addme.ge/"
                style={{
                  color: "#6966EE",
                }}
                rel="noreferrer"
              >
                Powered by
                <img
                  src={AddmeLogo}
                  alt=""
                  style={{ width: "80px", height: "60px" }}
                />
              </a>{" "}
            </div>
          </div>
        </figure>

        <img
          className="logo d-md-none"
          src={AgileLogo}
          alt=""
          style={{ width: "150px", height: "30px" }}
        />
        <figure className="d-flex d-md-none gap-1 align-items-center">
          <div className="position-relative">
            <div
              className="position-absolute   align-items-center d-flex "
              style={{
                width: "200px",
                top: "-33px",
              }}
            >
              <a
                className="text-decoration-none "
                target="_blank"
                href="https://addme.ge/"
                style={{
                  color: "#6966EE",
                  fontFamily: "georgian_bold",
                  fontSize: "0.8rem",
                }}
                rel="noreferrer"
              >
                Powered by
                <img
                  src={AddmeLogo}
                  alt=""
                  style={{ width: "80px", height: "70px" }}
                />
              </a>{" "}
            </div>
          </div>
        </figure>
      </header>

      <main className="d-md-flex flex-column justify-content-md-center  mt-4 pt-3 mt-md-5  ">
        {/* Links */}
        <section
          className="d-flex flex-column  justify-content-center align-items-center mb-3 mt-4"
          style={{
            // width: "100vw",
            fontSize: "0.8rem",
            fontFamily: "georgian_bold",
          }}
        >
          <span className="text-center   text-black fw-bold ">
            კორპორატიული ტრენინგები
          </span>
          <span
            className="text-center   text-black fw-bold "
            style={{
              height: "12px",
            }}
          >
            საერთაშორისო სერტიფიცირება
          </span>
        </section>
        {/* Banner */}
        <figure className="mx-auto d-flex  justify-content-center my-3 ">
          <img
            src={AgileBanner}
            alt=""
            className="d-md-none img-fluid w-75 "
          />
          <img
            src={AgileBanner}
            alt=""
            className="d-none d-md-block img-fluid w-50"
          />
        </figure>
        <div className="mt-4">
          <a
            href="https://www.agilementor.ge/agile-ივენთები"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none text-black fs-6      text-center  py-2 px-4 rounded-3"
            style={{
              border: "2px solid black",
              fontFamily: "georgian_bold",
            }}
          >
            Event Agenda
          </a>
        </div>
        {/* icons */}
        <section className="d-flex gap-3  justify-content-center align-items-center  mt-3 mx-auto ">
          {contactInfo.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              key={index}
              className={item.className}
              style={item.style}
              rel="noreferrer"
            >
              {item.icon}
            </a>
          ))}
          <a
            href="https://www.udemy.com/course/agile-scrum-scrum-guide-2020"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={"./images/icons/icons8-udemy.svg"}
              alt=""
              onClick={() => {}}
              style={{
                width: "65px",
                height: "55px",
              }}
            />
          </a>
        </section>

        {/* Trainers */}
        <section
          className="container d-flex flex-column justify-content-center align-items-center  gap-2    mt-1 "
          style={{
            backgroundColor: "#FDD835",
            width: "100vw",
            left: "-60px",
          }}
        >
          <div className="d-flex justify-content-center mt-3 ">
            <h1
              className="fs-6 "
              style={{
                fontFamily: "georgian_bold",
              }}
            >
              სპიკერები
            </h1>
          </div>

          <figure
            className="row row-cols-1 row-cols-md-3 gap-md-0 gap-3"
            style={{
              fontSize: "0.8rem",
            }}
          >
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column gap-3 justify-content-center  align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center ">
                  <img
                    src={Mariam}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center  gap-1">
                    <p className="mb-0">MARIA GHLONTI</p>
                    <p className="mb-0">AgileMentor</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center ">
                  <img
                    src={KhatiaDzadzua}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center  gap-1">
                    <p className="mb-0">KHATIA DZADZUA</p>
                    <p className="mb-0">HT SOLUTIONS</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center ">
                  <img
                    src={AleksandreGhviniashvili}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center  gap-1">
                    <p className="mb-0">ALEKSANDRE GHVINIASHVILI</p>
                    <p className="mb-0">aPeople</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center ">
                  <img
                    src={MariamPilipia}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column gap-1 justify-content-center ">
                    <p className="mb-0">MARIAM PILIPIA</p>
                    <p className="mb-0">GPI HOLDING</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column gap-3 align-items-center rounded-5">
                <div className="d-flex flex-column">
                  <img
                    src={GigiShengelia}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column gap-1">
                    <p className="mb-0">GIGI SHENGELIA</p>
                    <p className="mb-0">SPACE INT.</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column justify-content-center  gap-3 align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center ">
                  <img
                    src={Jemal}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center gap-1">
                    <p className="mb-0">JEMAL MAGLAPHERIDZE</p>
                    <p className="mb-0">EPAM</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column gap-3 justify-content-center align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center">
                  <img
                    src={LevanKokaia}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center gap-1">
                    <p className="mb-0">LEVAN KOKAIA</p>
                    <p className="mb-0">TBC</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column gap-3 justify-content-center align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center">
                  <img
                    src={AnaJikia}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center gap-1">
                    <p className="mb-0">ANA JIKIA</p>
                    <p className="mb-0">TBC</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column gap-3 justify-content-center align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center">
                  <img
                    src={NinoRevishvili}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center gap-1">
                    <p className="mb-0">NINO REVISHVILI</p>
                    <p className="mb-0">TBC</p>
                  </div>
                </div>
              </div>
            </figcaption>
            <figcaption className="col-3 mx-auto">
              <div className="cols d-flex flex-column justify-content-center gap-3 align-items-center rounded-5">
                <div className="d-flex flex-column justify-content-center ">
                  <img
                    src={LiliaShegeeva}
                    alt=""
                    className="img-fluid mx-2 mt-2 align-self-center "
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mx-2 mt-2 d-flex flex-column justify-content-center gap-1">
                    <p className="mb-0">LILIYA SHAGEYEVA</p>
                    <p className="mb-0">SPACE INT.</p>
                  </div>
                </div>
              </div>
            </figcaption>
          </figure>
        </section>

        {/* Offer */}
        <section
          className="d-flex flex-column  gap-1 justify-content-center mt-3 align-items-center pb-2 "
          // style={{
          //   m
          // }}
        >
          <div className="d-flex ">
            <img
              src="./images/icons/icons8-udemy.svg"
              alt=""
              style={{ width: "70px", height: "60px" }}
              className="mb-3 mb-md-0"
            />
            <img
              src={PromoCode}
              alt=""
              width={80}
              height={60}
            />
          </div>
          <article className="d-flex flex-column gap-1 justify-content-md-between  h-100 py-md-2 ">
            <p
              className="mb-0  text-wrap "
              style={{
                fontSize: "0.85rem",
              }}
            >
              <span
                style={{
                  fontFamily: "georgian_bold",
                }}
              >
                Agile Scrum
              </span>{" "}
              პირველი ქართულენოვანი კურსი{" "}
              <span
                style={{
                  fontFamily: "georgian_bold",
                }}
              >
                Udemy
              </span>{" "}
              -ზე
            </p>
            <p
              className="mb-0 "
              style={{
                fontSize: "0.85rem",
              }}
            >
              25 აპრილამდე PROMO CODE :{" "}
              <span
                style={{
                  fontFamily: "georgian_bold",
                }}
              >
                79222145F35AC676D744
              </span>
            </p>
          </article>
        </section>
        {/* countdown */}
        <div className="d-flex flex-column align-items-center justify-content-center mt-2">
          <p className="small">დარჩენილია</p>
          <p
            className="small py-2 px-3 text-center  rounded-3"
            style={{
              color: "black",
              border: "2px solid black",
              width: "200px",
            }}
          >
            {countDown(time)}
          </p>
        </div>
      </main>
      <footer className="d-flex flex-column gap-3  align-items-center mt-1 mb-4">
        <div
          className="py-1 position-relative align-content-center  d-flex  justify-content-center    text-white  text-decoration-none small rounded-3 mb-md-2   "
          style={{
            backgroundColor: "#6966EE",
            cursor: "pointer",
            paddingLeft: "20px",
            paddingRight: "4px",
          }}
          onClick={copyToClipboard}
        >
          <img
            src={AddmeWaveLogoWhite}
            alt=""
            className="position-absolute "
            style={{ width: "25px", height: "20px", left: "-4px", top: "3px" }}
          />
          <span className="">
            Create Your{" "}
            <span
              style={{
                fontFamily: "georgian_bold",
              }}
            >
              AddMe
            </span>
          </span>
        </div>
      </footer>
      <Share
        username={username.toLocaleLowerCase()}
        isUserPage={isUserPage}
      />
    </div>
  );
}
