import { Card, Skeleton } from "antd";
import { Link } from "react-router-dom";

export default function UserCards({
  userDataLoading,
  userData,
  handleGetUserQRs,
  skeletonCount,
}) {
  return (
    <>
      {userDataLoading
        ? Array.from({ length: skeletonCount }, (_, i) => (
            <Card
              key={i}
              style={{ width: 300, margin: 10 }}
            >
              <Skeleton active>
                <Card.Meta
                  title="Card Title"
                  description="Card Description"
                />
              </Skeleton>
            </Card>
          ))
        : userData?.data?.cards?.map(card => (
            <div
              className="card p-0 mb-3 ms-3 col-4"
              key={card.cardId}
            >
              <div className="card-header d-flex justify-content-between ">
                <h3>{card.cardName}</h3>
                <button
                  className="btn btn-primary"
                  onClick={() => handleGetUserQRs(card.cardId)}
                >
                  Show QR
                </button>
              </div>
              <div className="card-body">
                <p>Card Title : {card.cardName}</p>
                <Link
                  to={`${window.location.origin}/${card?.userName}`}
                >{`${window.location.origin}/${card?.userName}`}</Link>
              </div>
              <div className=" col-12 align-items-center d-flex justify-content-md-start justify-content-center  mb-3 ">
                <Link
                  to={`/analytics/${card.guid}`}
                  className="btn btn-primary-gradient py-2 px-4 ms-3 me-4"
                >
                  Analytics
                </Link>
              </div>
              <div className=" col-12 align-items-center d-flex justify-content-md-start justify-content-center  mb-3 ">
                <Link
                  to={`/cardqrviewanalytics/${card.guid}?typeId=1`}
                  className="btn btn-primary-gradient py-2 px-4 ms-3 me-4"
                >
                  Type 1 QR Analytics
                </Link>
              </div>
              <div className=" col-12 align-items-center d-flex justify-content-md-start justify-content-center  mb-3 ">
                <Link
                  to={`/cardqrviewanalytics/${card.guid}?typeId=2`}
                  className="btn btn-primary-gradient py-2 px-4 ms-3 me-4"
                >
                  Type 2 QR Analytics
                </Link>
              </div>
            </div>
          ))}
    </>
  );
}
