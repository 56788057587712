import { useEffect } from "react";
import { useLazyGetCardQuery } from "../Redux/services/CardApi";

export const useCardItemInputs = ({ cardId }) => {
  const [trigger, { data: card, isSuccess, isError }] = useLazyGetCardQuery();

  const cardNames = card?.data?.cardItemInputs
    .map(input => input.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  console.log(cardNames);
  // 'Firstname,Lastname,Adress,Company,Position,Phone1,Website,Whatsapp,Viber,Facebook,Instagram,Telegram,Email,Linkedin,Twitter,Tiktok,Skype,Github,Pinterest,Spotify'

  const getValuesByName = name => {
    return (
      card?.data?.cardItemInputs
        .filter(input => input.name === name)
        .map(input => input.value) || []
    );
  };

  useEffect(() => {
    trigger(cardId);
  }, [isSuccess, isError, trigger, cardId]);

  return {
    card,
    firstName: getValuesByName("Firstname"),
    lastName: getValuesByName("Lastname"),
    position: getValuesByName("Position"),
    instagram: getValuesByName("Instagram"),
    facebook: getValuesByName("Facebook"),
    twitter: getValuesByName("Twitter"),
    whatsapp: getValuesByName("Whatsapp"),
    viber: getValuesByName("Viber"),
    email: getValuesByName("Email"),
    linkedin: getValuesByName("Linkedin"),
    tiktok: getValuesByName("Tiktok"),
    skype: getValuesByName("Skype"),
    github: getValuesByName("Github"),
    pinterest: getValuesByName("Pinterest"),
    spotify: getValuesByName("Spotify"),
  };
};
