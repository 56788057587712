import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/navbar/navbar";
import {
  useGetShortURLsQuery,
  useDeleteShortURLMutation,
  useCreateShortURLMutation,
} from "../../Redux/services/ShortURLApi";

import { useSelector, useDispatch } from "react-redux";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";

export default function ShortURLs() {
  const [shortUrl, setShortUrl] = useState({
    name: "",
    link: "",
  });
  const { data: shortUrls } = useGetShortURLsQuery();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [createShortURL] = useCreateShortURLMutation();
  const [deleteShortURL] = useDeleteShortURLMutation();

  const { user } = useSelector(state => state.user);

  const handleShortURLValues = e => {
    setShortUrl(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCreateURL = async () => {
    const response = await createShortURL(shortUrl);
    if (response.data.success) {
      dispatch(
        setToastNotification({ message: t("shortURLCreate"), type: "success" })
      );
    }

    setShortUrl({
      name: "",
      link: "",
    });
  };

  const handleDeleteURL = async id => {
    const response = await deleteShortURL(id);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("shortURLDelete"), type: "success" })
        );
      }
    } catch (err) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
      console.log(err);
    }
  };

  return (
    <div>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="container-xxl mt-5 pt-5">
        <h1 className="fs-3">{t("yourShortURLs")}</h1>
        <div className="mt-3 d-flex flex-column gap-2">
          <input
            type="text"
            name="name"
            className="form-control mt-3"
            value={shortUrl.name}
            onChange={handleShortURLValues}
            placeholder={t("name")}
          />
          <input
            type="text"
            name="link"
            className="form-control "
            value={shortUrl.link}
            onChange={handleShortURLValues}
            placeholder={t("url")}
          />
          <button
            className="btn btn-primary-gradient mt-3"
            disabled={user.pricingPlan.shortLinkLimit === shortUrls?.data?.length}
            onClick={handleCreateURL}
          >
            {t("createShortURL")}
          </button>
        </div>

        <table className="table mt-5">
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>{t("shortURL")}</th>
              <th>{t("link")}</th>
              {user.pricingPlan.canAnalytics && <th>{t("analytics")}</th>}

              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {shortUrls?.data?.map(e => (
              <tr key={e.id}>
                <td>{e.name}</td>
                <td>
                  <a
                    href={`${window.location.origin}/u/${e.value}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${window.location.origin}/u/${e.value}`}
                  </a>
                </td>

                <td>{e.original}</td>
                {user.pricingPlan.canAnalytics && (
                  <td>
                    <Link
                      to={`/shorturlanalytics/${e?.guid}`}
                      className="btn btn-primary-gradient"
                    >
                      {t("analytics")}
                    </Link>
                  </td>
                )}

                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      handleDeleteURL(e.id);
                    }}
                  >
                    {t("delete")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="my-3 ">
          <Link
            className="btn btn-primary-gradient"
            to="/auth/profile"
          >
            {t("backToProfile")}
          </Link>
        </div>
      </div>
    </div>
  );
}
