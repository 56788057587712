const hostname = window.location.hostname;

let BASE_URL = "";

if (hostname === "localhost") {
  BASE_URL = "https://localhost:7279";
} else {
  BASE_URL = "https://api.addme.ge";
}

const config = {
  BASE_URL: "https://api.addme.ge",
  // BASE_URL: "https://testing.api.addme.ge",
  //  BASE_URL: BASE_URL,
};

export default config;
